import React from 'react';
import Helmet from 'react-helmet';

import Layout from '../../layouts/SimplePage';
import FirstScreenSection from '../../components/FirstScreenSection';
import GradesSection from '../../components/GradesSection';
import Calculator from '../../components/Calculator';
import Testimonials from '../../components/Testimonials';
import OurFeaturesSection from '../../components/OurFeaturesSection';
import { SeoTextSection, SeoTextHeading, SeoTextContent } from '../../components/SeoText';
import CTA from '../../components/CTA';


const PayForWritingPage = () => (
  <Layout
    pageWrapperClassName="pay-for-writing-page"
    headerClassName="header_white-bg"
  >

    <Helmet
      title="Pay for Essay Writing - Pay For Essays"
      meta={[
        {
          name: 'description',
          content: 'Pay for writing at TakeAwayEssay.com 📝 is an excellent writing service, offering professional writers and money-back guarantees. Pay for Essay Writing and get your order done on time with help from TakeAwayEssay.com 📝. Clients’ reviews prove our reliability and 24/7 customer support.',
        },

        {
          property: 'og:title',
          content: 'Pay for Essay Writing to Receive the Highest Quality and Greatest Speed | TakeAwayEssay.com',
        },
        {
          property: 'og:description',
          content: 'If you intend to pay for essay writing on TakeAwayEssay.com, read this informative article to define the major benefits and guarantees we propose. Just place an instant order now and enjoy assignments of the highest quality written by approved specialists.',
        },
        { property: 'og:site_name', content: 'Takeawayessay.com' },
        { property: 'og:type', content: 'website' },
        { property: 'og:image', content: 'https://takeawayessay.com/open-graph-img.jpg' },
      ]}
      link={[
        { rel: 'canonical', href: 'https://takeawayessay.com/pay-for-writing.html' },
      ]}
    />

    <FirstScreenSection containerClassName="first-screen-section--seo">
      <h2 className="first-screen-section__title first-screen-section__title_seo">
        Purchase an essay now —
        <br className="first-screen-section__title__br" />
        {' '}
        spare yourself the worry
      </h2>
      <p className="first-screen-section__heading first-screen-section__heading_seo">
        With us, you can always be sure
        <br className="first-screen-section__heading__br" />
        {' '}
        you will pay for 100% original content
      </p>
    </FirstScreenSection>

    <GradesSection />
    <Calculator />
    <Testimonials nameOfPageArray="home" />
    <OurFeaturesSection />

    <SeoTextSection>
      <SeoTextHeading>
        Pay for essays: pay for essay writing
      </SeoTextHeading>

      <SeoTextContent>
        <div className="seo-text-section__text__css-half-column">
          <p>
            “I will pay for essay if you solve my problems.” This is one of the frequently written
            online requests. They are left by college students who grow desperate because they
            cannot cope with their essays. They eagerly pay someone to get help with their papers.
            Students leave such calls for assistance because they may have poor writing and reading
            skills, lack the time, have personal problems, health issues, and something of the kind.
            If you face some impediments, you’re welcome to use our assistance.
          </p>
          <p>
            We are known as TakeAwayEssay.com. We are a highly reputed custom writing company famous
            throughout the globe.{' '}
            <strong>
              People pay for essay on our platform because we write assignments of the highest
              quality very quickly and in accordance with academic requirements. Additionally, we
              offer a wide range of other advantages and set reasonable prices.
            </strong>{' '}
            You won’t regret it if you prefer our essay writing company.
          </p>

          <h2 className="h3">Pay to write essay at an affordable price</h2>
          <p>
            When people intend to pay to write essay, they commonly hope to find affordable prices.
            We’re always happy to make such a dream come true. Accordingly, we set pretty cheap
            prices to meet the financial possibilities of ordinary clients.
          </p>
          <p>
            Moreover, you’re in full charge of your own orders. Fill out the order form and mention
            the most important details about your assignment. These are its quality (high school,
            college or university), type, length, urgency, and similar points. Their sum makes the
            total cost. See how much must be paid and pay to write essay if it satisfies you. In
            case you want to lower the cost, make the necessary adjustments. Everything is fair and
            square. You never overpay if you collaborate with our company.
          </p>
          <h2 className="h3">Paid essay writing offered by professionals</h2>
          <p>
            We are chosen by thousands of students thanks to the excellent services we provide. Our
            writers do real miracles to help you compose assignments of the top quality. Our paid
            essay writing is worth a try because our specialists are:
          </p>
          <ul className="dot-list">
            <li>Professional</li>
            <li>Certified</li>
            <li>Experienced</li>
            <li>Dedicate to your orders</li>
            <li>Flexible</li>
            <li>Quick</li>
            <li>Effective</li>
          </ul>
          <p>
            They possess advanced skills to fulfill any demand you may have. They write, cite,
            proofread, research, generate topics, create effective plans, edit and carry out many
            other important services. Therefore, you don’t simply pay for essay writing. You
            automatically receive other priorities.
          </p>
          <p>
            Our specialists tackle their orders according to the demands of your educational
            institution. Those demands are never violated. Approved professionals easily handle any
            essay, research paper, coursework, dissertation, presentation, review, personal
            statement, etc.
          </p>
          <h2 className="h3">Pay for essays online to beat urgent deadlines</h2>
          <p>
            Another reason why we’re so popular is the swiftness of our support. When you pay to
            write an essay on TakeAwayEssay.com, you meet even the shortest deadline. Most papers
            can be written in 3-4 hours or quicker. Even such complex orders like a dissertation are
            accomplished much faster if you get assistance from our website.
          </p>
          <p>
            Simply leave your instructions and set a clear deadline. We’ll evaluate the odds and if
            the conditions are manageable, you will receive a sample of your paper before the
            deadline expires. You’re always on time with our professional writers.
          </p>
          <h2 className="h3">Full anonymity and uniqueness</h2>
          <p>
            When you pay for essays online here, you enjoy two important guarantees. Firstly, we
            promise to protect your private data. We implement the most efficient safeguards to
            deflect all kinds of cyber threats. Undoubtedly, we never spread a word about our
            customers and their collaboration with our platform.
          </p>
          <p>
            Secondly, we compose only unique assignments. Specially assigned editors use the
            effective plagiarism-checking program, which searches through the Internet to define
            whether there are any matches with your assignments. If we detect any element of
            plagiarism, it is instantly eliminated from the texts. We quote and make references in
            accordance with APA, MLA, Chicago, Turabian, and other writing formats. This advantage
            is chargeless to save your earnings.
          </p>
          <h2 className="h3">About the placement of the orders</h2>
          <p>
            If you’ve finally decided to pay for essay writing on our platform, please, fill out the
            order form. It consists of several compulsory fields, which explain to us what you need.
            Besides, you should provide the contact information and mention your user’s name. Thus,
            we’ll know where to send notifications and orders and will be able to identify you in
            our system.
          </p>
          <p>
            The next phase is the payment. We ensure full security of any transactions made inside
            our company. Select the most convenient method and buy your paper. We assign writers
            according to your needs. You may fully trust our choice. Your personal helper won’t
            disappoint you. Get in touch with your helper to control the process of writing and/or
            make instant adjustments if necessary.
          </p>
          <p>
            We work 24/7 and so, you’re welcome to visit us whenever the need appears. Competent
            technicians will guide you through all our policies, rules, and restrictions to avoid
            misunderstandings. Our specialists already wait for your orders. Place them now and
            solve your academic issues.
          </p>
        </div>
      </SeoTextContent>
    </SeoTextSection>

    <CTA btnText="Order your paper">
      Nerve cells do not regenerate.
      <br />
      {' '}
      Save your nerves and buy that paper.
    </CTA>

  </Layout>
);

export default PayForWritingPage;
